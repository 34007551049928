<template>
  <b-sidebar
    id="sidebar-create-edit"
    ref="sidebar-create-edit"
    bg-variant="white"
    right
    backdrop
    shadow
    :title="
      this.dataModules.id == null
        ? 'Cadastrar Colaborador'
        : 'Editar Colaborador'
    "
  >
    <div>
      <!-- Nome -->
      <b-form-group label="* Nome:" label-for="i-nome" class="pl-1 pr-1">
        <b-form-input
          id="i-nome"
          type="text"
          placeholder="Ex: João"
          v-model="dataModules.nome"
        />
      </b-form-group>
      <!-- CPF -->
      <b-form-group label="* CPF:" label-for="i-cpf" class="pl-1 pr-1">
        <b-form-input
          id="i-cpf"
          type="text"
          placeholder="Ex: 123.456.789-12"
          v-model="dataModules.cpf"
        />
      </b-form-group>
      <!-- Regional -->
      <b-form-group
        label="* Regional:"
        label-for="i-regional"
        class="pl-1 pr-1"
      >
        <b-form-select
          id="i-regional"
          v-model="regional_id"
          :options="modules.regionais"
          value-field="id"
          text-field="nome"
          class="form-control"
        />
      </b-form-group>
      <!-- Agencia -->
      <b-form-group label="* Agencia:" label-for="i-agencia" class="pl-1 pr-1">
        <b-form-select
          :disabled="regional_id == null"
          id="i-agencia"
          v-model="dataModules.agencia_id"
          :options="moduleDetails.regionais.agencias"
          value-field="id"
          text-field="nome"
          class="form-control"
        />
      </b-form-group>
      <!-- Carteira -->
      <b-form-group
        label="* Carteira:"
        label-for="i-carteira"
        class="pl-1 pr-1"
      >
        <b-form-select
          class="form-content"
          v-model="dataModules.carteira_id"
          :options="modules.carteiras"
          value-field="id"
          text-field="nome"
        />
      </b-form-group>

      <b-row class="ml-1 mr-1 pb-1">
        <b-button variant="relief-primary" block @click="storeAndUpdate">
          {{ this.dataModules.id == null ? "Salvar" : "Editar" }}
        </b-button>
      </b-row>
      <b-row class="ml-1 mr-1 pb-1">
        <b-button variant="relief-danger" block @click="initValues">
          Limpar
        </b-button>
      </b-row>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable */
import { VBToggle } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    dataProp: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    mapActions,
    mapState,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      // Variáveis Global

      regional_id: null,

      dataModules: {
        nome: null,
        cpf: null,
        agencia_id: null,
        carteira_id: null,
        pathAPI: "colaboradores",
      },

    };
  },
  computed: {
    ...mapState("connectAPI", ["modules", "moduleDetails"]),
  },
  methods: {
    ...mapState("connectAPI", ["store", "update"]),

    initValues() {
      this.dataModules.id = null;
      this.dataModules.nome = null;
      this.dataModules.cpf = null;
      this.dataModules.agencia_id = null;
      this.dataModules.carteira_id = null;
    },

    fetchRegionais() {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "regionais",
          paginated: false,
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetchAgencias(value) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchDetails", {
          pathAPI: "regionais",
          paginated: false,
          id: value
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetchCarteira() {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "carteiras",
          paginated: false,
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    storeAndUpdate() {
      if (this.dataModules.id == null) {
        this.$store
          .dispatch("connectAPI/store", {
            data: this.dataModules,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Cadastrado com sucesso!",
                icon: "ThumbsUpIcon",
                text: "Operação executada com sucesso.",
                variant: "success",
              },
            });
            this.initValues();
          })
          .finally(() => {
            this.$emit("fetch-all");
            this.closeSidebar();
          });
      } else {
        this.$store
          .dispatch("connectAPI/update", {
            data: this.dataModules,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Atualizado com sucesso!",
                icon: "ThumbsUpIcon",
                text: "Operação executada com sucesso.",
                variant: "info",
              },
            });
            this.initValues();
          })
          .finally(() => {
            this.$refs["sidebar-create-edit"].hide();
            this.$emit("fetch-all");
          });
      }
    },

    closeSidebar() {
      this.$refs["sidebar-create-edit"].hide();
    },
  },
  watch: {
    dataProp: {
      immediate: true,
      handler(val, oldVal) {
        if (!val) return;
        if (this.dataProp.id == null) {
          this.initValues();
        } else {
          this.regional_id = this.dataProp.regional_id;
          this.dataModules.id = this.dataProp.id;
          this.dataModules.nome = this.dataProp.nome;
          this.dataModules.cpf = this.dataProp.cpf;
          this.dataModules.agencia_id = this.dataProp.agencia_id;
          this.dataModules.carteira_id = this.dataProp.carteira_id;
          
        }
      },
    },
    regional_id(value){
      this.fetchAgencias(value);
    } 
  },
  created() {
    this.fetchCarteira();
    this.fetchRegionais();
  },
};
</script>